import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom"; // Import useNavigate
import { Footer, Navbar } from "../components";
import { signInWithEmailAndPassword } from "@firebase/auth";

import { auth } from "../firebase/firebase"; // adjust the path accordingly

const Login = () => {
  const [email, setEmail] = useState("");
const [password, setPassword] = useState("");
const [error, setError] = useState("");
const navigate = useNavigate();

const handleLogin = async (e) => {
  e.preventDefault();
  try {
      await signInWithEmailAndPassword(auth, email, password);
      // User is now signed in!
      
      // Navigate to the main page
      navigate('/');
  } catch (error) {
      console.error("Error signing in:", error);
      setError(
          <div>
              <p>Login failed. Please check your email and password.</p>
              <p>If the error persists, Support.</p>
          </div>
      );
  }
};

  return (
    <>
      <Navbar />
      <div className="container my-3 py-3">
        <h1 className="text-center">Login</h1>
        <hr />
        <div className="row my-4 h-100">
          <div className="col-md-4 col-lg-4 col-sm-8 mx-auto">
            <form>
              <div className="my-3">
                <label htmlFor="display-4">Email address</label>
                <input 
  type="email" 
  className="form-control" 
  id="floatingInput" 
  placeholder="name@example.com" 
  value={email} 
  onChange={(e) => setEmail(e.target.value)} 
/>
              </div>
              <div className="my-3">
                <label htmlFor="floatingPassword display-4">Password</label>
                <input 
  type="password" 
  className="form-control" 
  id="floatingPassword" 
  placeholder="Password" 
  value={password} 
  onChange={(e) => setPassword(e.target.value)} 
/>
              </div>
              <div className="my-3">
                <p>New Here? <Link to="/register" className="text-decoration-underline text-info">Register</Link> </p>
              </div>
              <div className="text-center">
              <button className="my-2 mx-auto btn btn-dark" type="submit" onClick={handleLogin}>
  Login
</button>
{error && <p className="text-danger">{error}</p>}
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Login;
