import React, { useState, useEffect } from 'react';
import { collection, getDocs } from '@firebase/firestore';
import { firestore, auth } from '../firebase/firebase';
import { useDispatch, useSelector } from "react-redux";
import { addCart, loadCartFromFirestore } from "../redux/action"; // Import the new action
import './products.css';
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import ImageModal from './ImageModal';

const Products = () => {
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState(data);
  const [loading, setLoading] = useState(false);
  let componentMounted = true;
  const [selectedProduct, setSelectedProduct] = useState(null); // Track the selected product
  const [selectedQuantity, setSelectedQuantity] = useState(1); // Track the selected quantity
  const [selectedType, setSelectedType] = useState(""); // Track the selected type
  const [selectedMessage, setSelectedMessage] = useState(""); // Initialize selectedMessage state

  const [customVariants, setCustomVariants] = useState([]);
const [selectedVariant, setSelectedVariant] = useState('');
const [customPrice, setCustomPrice] = useState('');

const dispatch = useDispatch();
const userUID = useSelector((state) => state.auth?.user?.uid);
useEffect(() => {
  const loadCartData = async () => {
    if (userUID) {
      try {
        // Load cart data from Firestore when the component mounts
        await dispatch(loadCartFromFirestore(userUID));

      
        const getProducts = async () => {
          // ...
        };
        getProducts();
      } catch (error) {
        console.error('Error loading cart:', error);
      } finally {
        // Set loading to false or perform any other necessary actions
        setLoading(false);
      }
    } else {
      // User is not signed in, you can handle this case if needed
      setLoading(false);
    }
  };

  // Call the function to load cart data
  loadCartData();
}, [dispatch, userUID]);


  const addProductToCart = () => {
    if (
      selectedProduct &&
      selectedType &&
      selectedQuantity > 0
    ) {
      const selectedVariant = selectedProduct.variants.find(
        (variant) => variant.name === selectedType
      );
  
      if (selectedVariant) {
        const productToAdd = {
          ...selectedProduct,
          type: selectedType,
          variant: selectedVariant.name, // Set the variant property
          price: selectedVariant.price, // Use the variant's price
          qty: selectedQuantity,
          message: selectedMessage, // Include the custom message
        };
        dispatch(addCart(productToAdd));
        setSelectedProduct(null);
        setSelectedType("");
        setSelectedQuantity(1);
        setSelectedMessage(""); // Clear the message input
      }
    }
  };
  
  const closeProductModal = () => {
    setSelectedProduct(null);
  };

  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (e.target.classList.contains('modal')) {
        closeProductModal();
      }
    };
  
    document.body.addEventListener('click', handleOutsideClick);
  
    return () => {
      document.body.removeEventListener('click', handleOutsideClick);
    };
  }, []);
  

  useEffect(() => {
    const getProducts = async () => {
      setLoading(true);
      try {
        const productsCollection = collection(firestore, 'products');
        const querySnapshot = await getDocs(productsCollection);
        const products = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        if (componentMounted) {
          setData(products);
          setFilter(products);
          setLoading(false);
        }
      } catch (error) {
        console.error('Error fetching products:', error);
        setLoading(false);
      }
    };

    getProducts();

    return () => {
      componentMounted = false;
    };
  }, []);

  const Loading = () => {
    return (
      <>
        <div className="col-12 py-5 text-center">
          <Skeleton height={40} width={560} />
        </div>
        <div className="col-md-4 col-sm-6 col-xs-8 col-12 mb-4">
          <Skeleton height={592} />
        </div>
        <div className="col-md-4 col-sm-6 col-xs-8 col-12 mb-4">
          <Skeleton height={592} />
        </div>
        <div className="col-md-4 col-sm-6 col-xs-8 col-12 mb-4">
          <Skeleton height={592} />
        </div>
        <div className="col-md-4 col-sm-6 col-xs-8 col-12 mb-4">
          <Skeleton height={592} />
        </div>
        <div className="col-md-4 col-sm-6 col-xs-8 col-12 mb-4">
          <Skeleton height={592} />
        </div>
        <div className="col-md-4 col-sm-6 col-xs-8 col-12 mb-4">
          <Skeleton height={592} />
        </div>
      </>
    );
  };

  const filterProduct = (cat) => {
    const updatedList = data.filter((item) => item.category === cat);
    setFilter(updatedList);
  }
  const [isImageOverlayOpen, setImageOverlayOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');

  const openImageOverlay = (imageUrl) => {
    setSelectedImage(imageUrl);
    setImageOverlayOpen(true);
  };

  const closeImageOverlay = () => {
    setImageOverlayOpen(false);
    setSelectedImage('');
  };

  const ShowProducts = () => {
    return (
      <>
        <div className="buttons text-center py-5">
          
        </div>
       {filter.map((product) => {
          return (
            <div
              id={product.id}
              key={product.id}
              className="col-md-4 col-sm-6 col-xs-8 col-12 mb-4"
            >
           <div className="card h-100">
        {/* Add an onClick event handler to the image */}
        <img
  className="card-img-top p-3"
  src={product.image}
  alt="Card"
  height={300}
  style={{ maxWidth: '100%' }} // Add this style
  onClick={() => {
    openImageOverlay(product.image);
  }}
/> {isImageOverlayOpen && (
  <div className="image-overlay-container" onClick={closeImageOverlay}>
   <img
  className="enlarged-image"
  src={selectedImage}
  alt="Enlarged"
  onClick={(e) => {
    e.stopPropagation(); // Prevent the click event from propagating to the overlay container
    closeImageOverlay(); // Close the image overlay when the image is clicked
  }}
/>
  </div>
)}
                <div className="card-body">
                  <h5 className="card-title">{product.title}</h5>
                  <p className="card-text">
                    {product.description}
                  </p>
                  {/* Move the button outside the card-body for better spacing */}
                </div>
                {product.variants ? (
                  <div className="list-group list-group-flush">
                    <li className="list-group-item lead">
                    <table className="table">
  <thead>
    <tr>
      <th>Product Options</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>
        <div>
          <label htmlFor={`variant_${product.id}`}></label>
          <select
            id={`variant_${product.id}`}
            value={selectedVariant || product.variants[0].name}
            onChange={(e) => setSelectedVariant(e.target.value)}
            className="form-control"
          >
            {product.variants.map((variant) => (
              <option key={variant.name} value={variant.name}>
                {variant.name} - ${variant.price} USD
              </option>
            ))}
          </select>
        </div>
      </td>
    </tr>
  </tbody>
</table>
                    </li>
                  </div>
                ) : (
                  <div className="card-body">
                    <p className="lead">$ {product.price}</p>
                  </div>
                )}
                {/* Move the button outside the card-body for better spacing */}
                <div className="card-footer">
                <button
  className="btn btn-dark m-1"
  onClick={() => {
    setSelectedProduct(product);
    setSelectedType(selectedVariant);  // Use the value from the dropdown
  }}
>
  Choose Options
</button>
                </div>
              </div>
            </div>
          );
        })}
      </>
    );
  };

  return (
    <>
      <div className="container my-3 py-3">
        <div className="row">
          <div className="col-12">
            <h2 className="display-5 text-center">Latest Products</h2>
            <hr />
          </div>
          {loading ? <Loading /> : <ShowProducts />}
        </div>
      </div>
      {selectedProduct && (
        <div className="modal">
          <div className="modal-content">
          <span className="close-modal" onClick={closeProductModal}>
            &times;
          </span>
            <h3>{selectedProduct.title}</h3>
            <img
  className={`card-img-top p-3 custom-image`}
  src={selectedProduct.image}
  alt="Card"
/>
            {selectedProduct.variants && (
              <div>
                <label htmlFor="type">Product Options</label>
<select
  id="type"
  onChange={(e) => setSelectedType(e.target.value)}
  value={selectedType}
  className="form-control" // Add this class
>
  <option value="">Select Type</option>
  {selectedProduct.variants.map((variant) => (
    <option key={variant.name} value={variant.name}>
      {variant.name} - ${variant.price} USD
    </option>
  ))}
</select>
              </div>
            )}
            <label htmlFor="quantity">Quantity</label>
            <input
              id="quantity"
              type="number"
              min="1"
              value={selectedQuantity}
              onChange={(e) => setSelectedQuantity(parseInt(e.target.value))}
            />
           <label htmlFor="message">Custom Message</label>
<textarea
  id="message"
  value={selectedMessage}
  onChange={(e) => setSelectedMessage(e.target.value)}
  rows="4" // You can adjust the number of rows as needed
  placeholder="Enter your custom message here..."
></textarea>
            <button className="btn btn-dark m-1" onClick={addProductToCart}>
              Add to Cart
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default Products;
