import React, { useEffect, useState } from 'react'; // Import useEffect and useState
import ReactDOM from 'react-dom/client';
import '../node_modules/font-awesome/css/font-awesome.min.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './redux/store';
import AdminGuard from './pages/AdminGuard';
import { Home, Product, Products, AboutPage, ContactPage, Cart, Login, Register, Checkout, PageNotFound } from "./pages";
import { useDispatch } from "react-redux";
import { auth } from "./firebase/firebase";
import AuthListener from './AuthListener.js'
const isAdmin = false;  // Just a placeholder. Replace this with your actual logic.

const App = () => {
  const [isLoading, setIsLoading] = useState(true); // State to track loading

  useEffect(() => {
    // Simulate data loading with a delay (replace with your actual data loading logic)
    setTimeout(() => {
      setIsLoading(false); // Set loading to false after the delay
    }, 2000); // Adjust the delay as needed
  }, []);

  // Render loading screen while isLoading is true
  if (isLoading) {
    return (
      <div className="loading-screen">
        <p>Loading...</p>
      </div>
    );
  }

  // Render the main application content once loading is complete
  return (
    <BrowserRouter>
      <Provider store={store}>
      <AuthListener>
        <Routes>
          <Route path="/admin" element={<AdminGuard />} />
          <Route path="/" element={<Home />} />
          <Route path="/product" element={<Products />} />
          <Route path="/product/:id" element={<Product />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route path="*" element={<PageNotFound />} />
          <Route path="/product/*" element={<PageNotFound />} />
        </Routes>
        </AuthListener>
      </Provider>
    </BrowserRouter>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />); // Render the App component
