import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { auth } from "./firebase/firebase";

const AuthListener = ({ children }) => {
    const dispatch = useDispatch();

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(user => {
            if (user) {
                dispatch({
                    type: "AUTH_SUCCESS",
                    payload: { uid: user.uid }
                });
            } else {
                console.log("No user logged in.");
                dispatch({
                    type: "LOGOUT"
                });
            }
        });

        return () => unsubscribe(); // Cleanup the listener on unmount
    }, [dispatch]);

    return children;
};

export default AuthListener;
