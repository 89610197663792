import { doc, setDoc,getDoc } from '@firebase/firestore';
import { firestore, auth } from '../../firebase/firebase';

// Action type
export const LOAD_CART_FROM_FIRESTORE = 'LOAD_CART_FROM_FIRESTORE';

// Action creator to load the cart from Firestore
export const loadCartFromFirestore = (userUID) => async (dispatch) => {
    try {
      // Create a reference to the user's cart document
      const cartRef = doc(firestore, 'carts', userUID);
  
      // Get the cart document data
      const cartDoc = await getDoc(cartRef);
  
      if (cartDoc.exists()) {
        // Dispatch an action to load the cart data into the Redux store
        dispatch({
          type: LOAD_CART_FROM_FIRESTORE,
          payload: cartDoc.data().items,
        });
      }
    } catch (error) {
      console.error('Error loading cart from Firestore:', error);
    }
  };

export const loadCart = (items) => ({
    type: 'LOAD_CART',
    payload: items
});

export const addCart = (product) => async (dispatch, getState) => {
    dispatch({ type: 'ADDITEM', payload: product });
  
    // Save to Firestore
    const userUID = auth.currentUser?.uid;
    if (userUID) {
      const cartRef = doc(firestore, 'carts', userUID);
      await setDoc(cartRef, { items: getState().handleCart }, { merge: true });
    }
};

export const delCart = (product) => async (dispatch, getState) => {
    dispatch({ type: 'DELITEM', payload: product });
  
    // Save to Firestore
    const userUID = auth.currentUser?.uid;
    if (userUID) {
      const cartRef = doc(firestore, 'carts', userUID);
      await setDoc(cartRef, { items: getState().handleCart }, { merge: true });
    }
};
