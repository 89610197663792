import React, { useState, useRef, useEffect } from "react";
import { Footer, Navbar } from "../components";
import { useSelector, useDispatch } from "react-redux";
import { addCart, delCart } from "../redux/action";
import { Link } from "react-router-dom";
import emailjs from "emailjs-com"; // Import EmailJS library

// import { parseString } from "xml2js"; // Import XML to JSON parser
// import "buffer";
// import "timers-browserify";
const disclaimerStyle = {
  fontSize: '0.8rem',
  color: 'red',
  marginTop: '10px'
};
const Cart = () => {
  const state = useSelector((state) => state.handleCart);
  const dispatch = useDispatch();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  // const [destinationZip, setDestinationZip] = useState(""); // User's entered destination ZIP code
  // const [address, setAddress] = useState(""); // User's entered address
  const form = useRef(null);
  const [formSubmitted, setFormSubmitted] = useState(false);
  // const [shippingRates, setShippingRates] = useState([]);
  // const [totalWeight, setTotalWeight] = useState(0);
  // const [shippingCost, setShippingCost] = useState(0); // Shipping cost to be displayed
  // const [fetchingShipping, setFetchingShipping] = useState(false); // Flag to control API requests

  // useEffect(() => {
  //   // Calculate the total weight of the items in the cart (in ounces or pounds)
  //   let cartWeight = 0;
  //   state.forEach((item) => {
  //     cartWeight += item.weight; // Adjust this line to get the weight from your data
  //   });
  //   setTotalWeight(cartWeight);

  //   // Only calculate shipping if a destination ZIP code is entered
  //   if (destinationZip) {
  //     // Make an API request to USPS Price Calculators API with the user's entered ZIP code
  //     fetch(
  //       `https://secure.shippingapis.com/ShippingAPI.dll?API=RateV4&XML=<RateV4Request USERID="580A38MAGSS49">
  //         <Package ID="1ST">
  //           <Service>PRIORITY</Service>
  //           <ZipOrigination>12345</ZipOrigination>
  //           <ZipDestination>${destinationZip}</ZipDestination> // Use user's entered ZIP code
  //           <Pounds>${Math.floor(totalWeight / 16)}</Pounds>
  //           <Ounces>${totalWeight % 16}</Ounces>
  //           <Container>RECTANGULAR</Container>
  //           <Size>LARGE</Size>
  //         </Package>
  //       </RateV4Request>`
  //     )
  //       .then((response) => response.text())
  //       .then((data) => {
  //         // Parse the XML response here and extract the shipping rates
  //         parseString(data, (err, result) => {
  //           if (!err) {
  //             const rates = result.RateV4Response.Package[0].Postage;
  //             setShippingRates(rates);
  //             // Calculate the shipping cost based on the response, e.g., rates[0].Rate
  //             // You may need to adjust this depending on the structure of the API response
  //             const calculatedShippingCost = parseFloat(rates[0].Rate);
  //             setShippingCost(calculatedShippingCost);
  //             setFetchingShipping(false); // Reset the flag after the request is complete
  //           }
  //         });
  //       })
  //       .catch((error) => {
  //         console.error('Error fetching shipping rates:', error);
  //         setFetchingShipping(false); // Reset the flag on error
  //       });
  //   }
  // }, [totalWeight, destinationZip]);

  // const calculateShipping = () => {
  //   if (destinationZip) {
  //     setFetchingShipping(true); // Set the flag to indicate that a request is in progress

  //     // Make an API request to USPS Price Calculators API with the user's entered ZIP code
  //     fetch(
  //       `https://secure.shippingapis.com/ShippingAPI.dll?API=RateV4&XML=<RateV4Request USERID="YourUserID">
  //         <Package ID="1ST">
  //           <Service>PRIORITY</Service>
  //           <ZipOrigination>12345</ZipOrigination>
  //           <ZipDestination>${destinationZip}</ZipDestination> // Use user's entered ZIP code
  //           <Pounds>${Math.floor(totalWeight / 16)}</Pounds>
  //           <Ounces>${totalWeight % 16}</Ounces>
  //           <Container>RECTANGULAR</Container>
  //           <Size>LARGE</Size>
  //         </Package>
  //       </RateV4Request>`
  //     )
  //       .then((response) => response.text())
  //       .then((data) => {
  //         // Parse the XML response here and extract the shipping rates
  //         parseString(data, (err, result) => {
  //           if (!err) {
  //             const rates = result.RateV4Response.Package[0].Postage;
  //             setShippingRates(rates);
  //             // Calculate the shipping cost based on the response, e.g., rates[0].Rate
  //             // You may need to adjust this depending on the structure of the API response
  //             const calculatedShippingCost = parseFloat(rates[0].Rate);
  //             setShippingCost(calculatedShippingCost);
  //             setFetchingShipping(false); // Reset the flag after the request is complete
  //           }
  //         });
  //       })
  //       .catch((error) => {
  //         console.error('Error fetching shipping rates:', error);
  //         setFetchingShipping(false); // Reset the flag on error
  //       });
  //   }
  // };

  
  const handleCheckout = (e) => {
    e.preventDefault();
  
    let cartMessage = ''; // Initialize an empty string for cart messages
  
    state.forEach((item) => {
      const itemMessage = item.message || "No custom message"; // Use custom message or default if not provided
      cartMessage += `${item.title} (${item.variant}, ${item.size}) x ${item.qty} - $${item.price * item.qty}\nCustom Message: ${itemMessage}\n`;
    });
  
    // Append cart items and shipping rates to the message field
    const fullMessage = `${message}\n\nCart Items:\n${cartMessage}`;
  
    // Use a form to send the email
    const form = document.createElement("form");
    form.style.display = "none";
    form.innerHTML = `
      <input type="text" name="to_name" value="${name}" />
      <input type="text" name="from_name" value="${email}" />
      <textarea name="message">${fullMessage}</textarea>
    `;
    document.body.appendChild(form);
  
    emailjs
      .sendForm(
        'service_mcpvqyb',
        'template_lc3qt4c',
        form,
        'i9YGa4-F_I-ge7LM_'
      )
      .then(
        (result) => {
          console.log(result.text);
          setFormSubmitted(true);
          // Handle successful email sending here
        },
        (error) => {
          console.log(error.text);
          // Handle error here
        }
      );
  
    // Remove the form element from the DOM after submission
    document.body.removeChild(form);
  };
  const EmptyCart = () => {
    return (
      <div className="container">
        <div className="row">
          <div className="col-md-12 py-5 bg-light text-center">
            <h4 className="p-3 display-5">Your Cart is Empty</h4>
            <Link to="/" className="btn  btn-outline-dark mx-4">
              <i className="fa fa-arrow-left"></i> Continue Shopping
            </Link>
          </div>
        </div>
      </div>
    );
  };

  const addItem = (product) => {
    const updatedProduct = { ...product, qty: product.qty + 1, variant: product.variant };
    dispatch(addCart(updatedProduct));
    console.log("i am here",product.variant,product.qty)
  };
  
  const removeItem = (product) => {
    dispatch(delCart(product));
  };

  const ShowCart = () => {
    let subtotal = 0;
    let totalItems = 0;
  
    state.forEach((item) => {
      const itemSubtotal = item.price * item.qty;
      subtotal += itemSubtotal;
      totalItems += item.qty;
    });
  
    return (
      <>
        <section className="h-100 gradient-custom">
          <div className="container py-5">
            <div className="row d-flex justify-content-center my-4">
              <div className="col-md-8">
                <div className="card mb-4">
                  <div className="card-header py-3">
                    <h5 className="mb-0">Item List</h5>
                  </div>
                  <div className="card-body">
                    {state.map((item) => {
                      const itemSubtotal = item.price * item.qty;
  
                      return (
                        <div key={item.id}>
                          <div className="row d-flex align-items-center">
                            <div className="col-lg-3 col-md-12">
                              <div
                                className="bg-image rounded"
                                data-mdb-ripple-color="light"
                              >
                                <img
                                  src={item.image}
                                  alt={item.title}
                                  width={100}
                                  height={75}
                                />
                                <p>Size: {item.size}</p>
                                <p>Variant: {item.variant}</p>
                                {item.message && ( // Check if a custom message exists
                                  <p>Custom Message: {item.message}</p>
                                )}
                              </div>
                            </div>
  
                            <div className="col-lg-5 col-md-6">
                              <p>
                                <strong>{item.title}</strong>
                              </p>
                            </div>
  
                            <div className="col-lg-4 col-md-6">
                              <div
                                className="d-flex mb-4"
                                style={{ maxWidth: "300px" }}
                              >
                                <button
                                  className="btn px-3"
                                  onClick={() => {
                                    removeItem(item);
                                  }}
                                >
                                  <i className="fas fa-minus"></i>
                                </button>
  
                                <p className="mx-5">{item.qty}</p>
  
                                <button
                                  className="btn px-3"
                                  onClick={() => {
                                    addItem(item);
                                  }}
                                >
                                  <i className="fas fa-plus"></i>
                                </button>
                              </div>
  
                              <p className="text-start text-md-center">
                                <strong>
                                  <span className="text-muted">{item.qty}</span>{" "}
                                  x ${item.price} = ${itemSubtotal}
                                </strong>
                              </p>
                            </div>
                          </div>
  
                          <hr className="my-4" />
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card mb-4">
                  <div className="card-header py-3 bg-light">
                    <h5 className="mb-0">Order Summary</h5>
                  </div>
                  <div className="card-body">
                    <ul className="list-group list-group-flush">
                      <li className="list-group-item d-flex justify-content-between align-items-center border-0 px-0 pb-0">
                        Products ({totalItems})<span>${Math.round(subtotal)}</span>
                      </li>
                      <li className="list-group-item d-flex justify-content-between align-items-center border-0 px-0 mb-3">
                        <div>
                          <strong>Total amount</strong>
                        </div>
                        <span>
                          <strong>${subtotal.toFixed(2)}</strong>
                        </span>
                      </li>
                        <p style={disclaimerStyle}>*Shipping will be calculated after processing. You will receive an email with the final amount including shipping costs.</p>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  };

  return (
    <div>
      <Navbar />
      <div className="container my-3 py-3">
        <h1 className="text-center">Cart</h1>
        <hr />
        {state.length > 0 ? (
          <ShowCart />
        ) : (
          <EmptyCart />
        )}
        <div className="text-center">
          {/* <h2>Shipping Information</h2>
          <div className="form my-3">
            <label htmlFor="Address">Address</label>
            <input
              type="text"
              className="form-control"
              id="Address"
              name="address"
              placeholder="Enter your address"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              required
            />
          </div>
          <div className="form my-3">
            <label htmlFor="DestinationZip">Destination ZIP Code</label>
            <input
              type="text"
              className="form-control"
              id="DestinationZip"
              name="destination_zip"
              placeholder="Enter ZIP code"
              value={destinationZip}
              onChange={(e) => setDestinationZip(e.target.value)}
              required
            />
          </div>
          {fetchingShipping ? (
            <p className="text-center">Calculating Shipping...</p>
          ) : (
            <button
              className="btn btn-dark"
              onClick={calculateShipping}
              disabled={!destinationZip || totalWeight <= 0}
            >
              Calculate Shipping
            </button>
          )}
          {shippingCost > 0 && (
            <div className="text-center">
              <h3>Estimated Shipping Cost</h3>
              <p>${shippingCost.toFixed(2)}</p>
            </div> */}
          
          <h2>Send Inquiry</h2>
          {formSubmitted ? (
    <div className="alert alert-success">
      Thank you for your message! We will get back to you soon.
    </div>
  ) : (
    <div>
      <form ref={form} onSubmit={handleCheckout}>
        <div className="form my-3">
          <label htmlFor="Name">Name</label>
          <input
            type="text"
            className="form-control"
            id="Name"
            name="to_name"
            placeholder="Enter your name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </div>
        <div className="form my-3">
          <label htmlFor="Email">Email</label>
          <input
            type="email"
            className="form-control"
            id="Email"
            name="from_name"
            placeholder="name@example.com"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="form my-3">
          <label htmlFor="Message">Message</label>
          <textarea
            rows={5}
            className="form-control"
            id="Message"
            name="message"
            placeholder="Enter your message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            required
          />
        </div>
        <button className="btn btn-dark btn-lg btn-block" type="submit">
          Deliver Your Dreams (Submit) 🌠
        </button>
      </form>
    </div>
  )}
</div>
      </div>
      <Footer />
    </div>
  );
};

export default Cart;
