import React, { useState, useEffect} from "react";
import { NavLink } from 'react-router-dom'
import { useSelector } from 'react-redux';
import { onAuthStateChanged, signOut } from '@firebase/auth';
import { auth } from '../firebase/firebase'; //
const Navbar = () => {
    const [currentUser, setCurrentUser] = useState(null);
    const state = useSelector(state => state.handleCart)

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                setCurrentUser(user);
            } else {
                setCurrentUser(null);
            }
        });

        return () => unsubscribe(); // This ensures the listener is detached when the component is unmounted
    }, []);

    const handleLogout = async () => {
        try {
            await signOut(auth);
            setCurrentUser(null);
        } catch (error) {
            console.error("Error signing out:", error);
        }
    };

    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-light py-3 sticky-top">
            <div className="container">
                <NavLink className="navbar-brand fw-bold fs-4 px-2" to="/"> Mag's Shop Shop</NavLink>
                <button className="navbar-toggler mx-2" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>

                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav m-auto my-2 text-center">
                        <li className="nav-item">
                            <NavLink className="nav-link" to="/">Home </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link" to="/product">Products</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link" to="/about">About</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link" to="/contact">Contact</NavLink>
                        </li>
                    </ul>
                    <div className="buttons text-center">
            {currentUser ? (
                <>
                    <span className="mr-3">Welcome, {currentUser.displayName}</span>
                    <button onClick={handleLogout} className="btn btn-outline-dark m-2">
                        Logout
                    </button>
                    <NavLink to="/cart" className="btn btn-outline-dark m-2">
                        <i className="fa fa-cart-shopping mr-1"></i> Cart ({state.length})
                    </NavLink>
                </>
            ) : (
                <>
                    <NavLink to="/login" className="btn btn-outline-dark m-2">
                        <i className="fa fa-sign-in-alt mr-1"></i> Login
                    </NavLink>
                    <NavLink to="/register" className="btn btn-outline-dark m-2">
                        <i className="fa fa-user-plus mr-1"></i> Register
                    </NavLink>
                </>
            )}
        </div>
                </div>


            </div>
        </nav>
    )
}

export default Navbar