const initialAuthState = {
    user: null,
    isLoading: false,
    error: null,
  };
  
  const authReducer = (state = initialAuthState, action) => {
    switch (action.type) {
      case "AUTH_START":
        return {
          ...state,
          isLoading: true,
          error: null,
        }; 
      case "AUTH_SUCCESS":
        return {
          ...state,
          user: action.payload,
          isLoading: false,
        };
      case "AUTH_ERROR":
        return {
          ...state,
          error: action.payload,
          isLoading: false,
        };
      case "LOGOUT":
        return {
          ...state,
          user: null,
        };
      default:
        return state;
    }
  };
  
  export default authReducer;
  