import React from 'react';

const ImageModal = ({ imageUrl, onClose }) => {
  return (
    <div className="image-modal">
      <div className="image-modal-content">
        <span className="close-image-modal" onClick={onClose}>
          &times;
        </span>
        <div className="zoomed-image-container">
          <img className="zoomed-image" src={imageUrl} alt="High-Quality" />
        </div>
      </div>
    </div>
  );
};

export default ImageModal;
