const cart = []

const handleCart = (state = cart, action) => {
  
  const product = action.payload;
  switch (action.type) {
    case "LOAD_CART_FROM_FIRESTORE": // New case to handle loading cart from Firebase
    return action.payload;
      
      case "ADDITEM":
        // Check if product already in cart
        const existingProduct = state.find(
          (x) =>
            x.id === product.id &&
            x.variant === product.variant &&
            x.size === product.size
        );
        if (existingProduct) {
          // Increase the quantity
          return state.map((x) =>
            x.id === product.id &&
            x.variant === product.variant &&
            x.size === product.size
              ? { ...x, qty: x.qty + 1 }
              : x
          );
        } else {
          return [...state, { ...product, qty: 1 }];
        }
      
      case "DELITEM":
        const existingProduct2 = state.find(
          (x) =>
            x.id === product.id &&
            x.variant === product.variant &&
            x.size === product.size
        );
        if (existingProduct2.qty === 1) {
          return state.filter(
            (x) =>
              !(
                x.id === existingProduct2.id &&
                x.variant === existingProduct2.variant &&
                x.size === existingProduct2.size
              )
          );
        } else {
          return state.map((x) =>
            x.id === product.id &&
            x.variant === product.variant &&
            x.size === product.size
              ? { ...x, qty: x.qty - 1 }
              : x
          );
        }
      
      default:
        return state;
    }
};

export default handleCart;
