import React, { useState, useEffect } from 'react';
import { firestore } from '../firebase/firebase';
import { collection, getDocs, addDoc, deleteDoc, doc, updateDoc } from '@firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from '@firebase/storage';
import { storage } from '../firebase/firebase'; // Adjust the path as needed


const AdminPanel = () => {
  // States for the form inputs
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [category, setCategory] = useState('');
  const [image, setImage] = useState('');
  const [variants, setVariants] = useState([{ name: '', price: '' }]);
  const [products, setProducts] = useState([]);
  const [password, setPassword] = useState('');
  const [isAdminAuthenticated, setIsAdminAuthenticated] = useState(false);
  const [editingProductId, setEditingProductId] = useState(null); // Track the product being edited
  const [imageFile, setImageFile] = useState(null);
  const [imageURL, setImageURL] = useState('');
  useEffect(() => {
    const isAdminAuthenticatedLocalStorage = localStorage.getItem('isAdminAuthenticated');
    if (isAdminAuthenticatedLocalStorage) {
      setIsAdminAuthenticated(true);
    }
  }, []);
  const logoutAdmin = () => {
    setIsAdminAuthenticated(false);

    // Remove the admin authentication flag from local storage
    localStorage.removeItem('isAdminAuthenticated');
  };

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImageFile(file); // Store the file object in state
      handleImageUpload(file); // Upload the image to Firebase Storage
    }
  };
  
  const handleImageUpload = async (file) => {
    // Create a Firebase Storage reference with just the file name
    const storageRef = ref(storage, `images/${file.name}`);
    
    try {
      // Upload the image file to Firebase Storage
      const snapshot = await uploadBytes(storageRef, file);
      
      // Get the download URL of the uploaded image
      const downloadURL = await getDownloadURL(snapshot.ref);
      
      // Set the image URL in the component state
      setImageURL(downloadURL);
    } catch (error) {
      console.error('Error uploading image: ', error);
    }
  };

  
  useEffect(() => {
    const fetchProducts = async () => {
      const productsCollection = collection(firestore, 'products');
      const querySnapshot = await getDocs(productsCollection);
      const productList = querySnapshot.docs.map((doc) => ({
        firestoreDocId: doc.id,
        ...doc.data(),
      }));
      setProducts(productList);
    };
    fetchProducts();
  }, []);

  const addVariant = () => {
    setVariants([...variants, { name: '', price: '' }]);
  };

  const removeVariant = (index) => {
    const updatedVariants = [...variants];
    updatedVariants.splice(index, 1);
    setVariants(updatedVariants);
  };

  const handleVariantChange = (index, key, value) => {
    const updatedVariants = [...variants];
    updatedVariants[index][key] = value;
    setVariants(updatedVariants);
  };

  const fetchProducts = async () => {
    const productsCollection = collection(firestore, 'products');
    const querySnapshot = await getDocs(productsCollection);
    const productList = querySnapshot.docs.map((doc) => ({
      firestoreDocId: doc.id,
      ...doc.data(),
    }));
    
    // Fetch and store image URLs for each product
    const productsWithImages = await Promise.all(
      productList.map(async (product) => {
        // Fetch the download URL for the image from Firebase Storage
        const imageRef = ref(storage, `images/${product.image}`);
        const downloadURL = await getDownloadURL(imageRef);
  
        // Return the product data with the image URL
        return {
          ...product,
          image: downloadURL,
        };
      })
    );
  
    setProducts(productsWithImages);
  };
  
  const addProduct = async () => {
  if (!isAdminAuthenticated) {
    alert('You are not authenticated to perform this action.');
    return;
  }


  // Set the variantsArray
  const variantsArray = variants.map((variant) => ({
    name: variant.name,
    price: parseFloat(variant.price),
  }));

  // Use imageURL if available, or set it to an empty string if not
  const imageUrlToUse = imageURL || '';

  // Set the product data
  const productData = {
    title,
    description,
    category,
    image: imageUrlToUse, // Set the image path in Firebase Storage
    variants: variantsArray,
  };

  const productsCollection = collection(firestore, 'products');

  try {
    // Upload the product data to Firestore
    await addDoc(productsCollection, productData);
    console.log('Product added successfully!');

    // Fetch and update the product list
    await fetchProducts();

    // Clear the form fields after adding a product
    setTitle('');
    setDescription('');
    setCategory('');
    setImage('');
    setImageURL(''); // Clear the imageURL state as well
    setVariants([{ name: '', price: '' }]);
  } catch (error) {
    window.location.reload();
   
  }
};

  const deleteProduct = async (itemId) => {
    if (!isAdminAuthenticated) {
      alert('You are not authenticated to perform this action.');
      return;
    }

    if (window.confirm('Are you sure you want to delete this item?')) {
      try {
        const productDoc = doc(firestore, 'products', String(itemId));
        await deleteDoc(productDoc);
        alert('Item deleted successfully!');
        // Remove the deleted product from the state
        setProducts((prevProducts) => prevProducts.filter((product) => product.firestoreDocId !== itemId));
      } catch (error) {
        console.error('Error deleting item: ', error);
        alert('An error occurred. Please try again.');
      }
    }
  };

  const editProduct = (product) => {
    setTitle(product.title);
    setDescription(product.description);
    setCategory(product.category);
    setImage(product.image);
  
    // Check if the product has variants, if not, set an empty array
    if (product.variants) {
      setVariants(product.variants);
    } else {
      setVariants([{ name: '', price: '' }]);
    }
  
    setEditingProductId(product.firestoreDocId);
  };

  const updateProduct = async () => {
    if (!isAdminAuthenticated) {
      alert('You are not authenticated to perform this action.');
      return;
    }

    try {
      const productDoc = doc(firestore, 'products', editingProductId);
      const variantsArray = variants.map((variant) => ({
        name: variant.name,
        price: parseFloat(variant.price),
      }));

      await updateDoc(productDoc, { title, description, category, image, variants: variantsArray });
      alert('Product updated successfully!');
      // Clear the form fields after updating
      setTitle('');
      setDescription('');
      setCategory('');
      setImage('');
      setVariants([{ name: '', price: '' }]);
      setEditingProductId(null);
    } catch (error) {
      console.error('Error updating product: ', error);
      alert('An error occurred. Please try again.');
    }
  };

  // Function to authenticate the user with a password
  const authenticateAdmin = () => {
    if (password === 'secret') {
      setIsAdminAuthenticated(true);

      // Store admin authentication flag in local storage
      localStorage.setItem('isAdminAuthenticated', 'true');
    } else {
      alert('Incorrect password. Please try again.');
    }
  };

  return (
    <div style={{backgroundColor: 'gray', maxWidth: '800px', margin: '0 auto', padding: '20px' }}>
      {/* Top Navigation Bar */}
      <div style={{ background: '#333', color: 'white', padding: '10px', display: 'flex', justifyContent: 'space-between' }}>
        <h2>Admin Panel</h2>
        {isAdminAuthenticated ? (
          <button
          onClick={logoutAdmin}
          style={{
            background: 'red',
            color: 'white',
            padding: '5px 10px',
            border: 'none',
            borderRadius: '4px',
            cursor: 'pointer',
          }}
        >
          Log Out
        </button>
        ) : null}
      </div>

      {/* Authentication Form */}
      {!isAdminAuthenticated && (
        <div style={{ background: '#f4f4f4', padding: '20px', borderRadius: '4px', marginTop: '20px' }}>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Admin Password"
            style={{ width: '100%', padding: '10px', marginBottom: '10px', border: '1px solid #ccc', borderRadius: '4px' }}
          />
          <button
            onClick={authenticateAdmin}
            style={{
              background: 'green',
              color: 'white',
              padding: '5px 10px',
              border: 'none',
              borderRadius: '4px',
              cursor: 'pointer',
            }}
          >
            Authenticate
          </button>
        </div>
      )}

      {/* Form to add/edit a product */}
      {isAdminAuthenticated && (
        <div style={{ background: '#', padding: '20px', borderRadius: '4px', marginTop: '20px' }}>
         <input
  type="text"
  value={title}
  onChange={(e) => setTitle(e.target.value)}
  placeholder="Product Title"
  style={{
    width: '100%',
    padding: '10px',
    marginBottom: '10px',
    border: '1px solid #ccc',
    borderRadius: '4px',
    backgroundColor: 'black', // Set the background color to black
    color: 'white', // Set the text color to white
  }}
/>

<input
  type="text"
  value={description}
  onChange={(e) => setDescription(e.target.value)}
  placeholder="Description"
  style={{
    width: '100%',
    padding: '10px',
    marginBottom: '10px',
    border: '1px solid #ccc',
    borderRadius: '4px',
    backgroundColor: 'black', // Set the background color to black
    color: 'white', // Set the text color to white
  }}
/>

<input
  type="text"
  value={category}
  onChange={(e) => setCategory(e.target.value)}
  placeholder="Category"
  style={{
    width: '100%',
    padding: '10px',
    marginBottom: '10px',
    border: '1px solid #ccc',
    borderRadius: '4px',
    backgroundColor: 'black', // Set the background color to black
    color: 'white', // Set the text color to white
  }}
/>
<input
  type="file"
  accept="image/*"
  onChange={handleFileInputChange}
  style={{
    marginBottom: '10px',
  }}
/>
<input
  type="text"
  value={image}
  onChange={(e) => setImage(e.target.value)}
  placeholder="Image URL"
  style={{
    width: '100%',
    padding: '10px',
    marginBottom: '10px',
    border: '1px solid #ccc',
    borderRadius: '4px',
    backgroundColor: 'black', // Set the background color to black
    color: 'white', // Set the text color to white
  }}
/>
          {variants.map((variant, index) => (
            <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
              <input
                type="text"
                placeholder="Variant Name"
                value={variant.name}
                onChange={(e) => handleVariantChange(index, 'name', e.target.value)}
                style={{ flex: '1', padding: '10px', border: '1px solid #ccc', borderRadius: '4px', marginRight: '10px', backgroundColor: 'black', color: 'white',}}
              />
              <input
                type="number"
                placeholder="Variant Price"
                value={variant.price}
                onChange={(e) => handleVariantChange(index, 'price', e.target.value)}
                style={{ flex: '1', padding: '10px', border: '1px solid #ccc', borderRadius: '4px', marginRight: '10px' , backgroundColor: 'black', color: 'white',}}
              />
              <button
                onClick={() => removeVariant(index)}
                style={{
                  background: 'red',
                  color: 'white',
                  padding: '5px 10px',
                  border: 'none',
                  borderRadius: '4px',
                  cursor: 'pointer',
                }}
              >
                Remove
              </button>
            </div>
          ))}
         <button
  onClick={addVariant}
  style={{
    background: 'dodgerblue',
    color: 'white',
    padding: '10px 20px', // Adjust padding to match other buttons

    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    marginBottom: '10px',
    margin: '10px 10px', // Adjust margin to match the spacing
  }}
>
  Add Variant
</button>
          {editingProductId ? (
            <button
              onClick={updateProduct}
              style={{
                background: 'green',
                color: 'white',
                padding: '10px 20px',
                border: 'none',
                borderRadius: '4px',
                cursor: 'pointer',
              }}
            >
              Update Product
            </button>
          ) : (
            <button
              onClick={addProduct}
              style={{
                background: 'green',
                color: 'white',
                padding: '10px 20px',
                border: 'none',
                borderRadius: '4px',
                cursor: 'pointer',
              }}
            >
              Add Product
            </button>
          )}
          <button
            onClick={() => {
              setTitle('');
              setDescription('');
              setCategory('');
              setImage('');
              setVariants([{ name: '', price: '' }]);
              setEditingProductId(null);
            }}
            style={{
              background: 'red',
              color: 'white',
              padding: '10px 20px',
              border: 'none',
              borderRadius: '4px',
              cursor: 'pointer',
              marginLeft: '10px',
            }}
          >
            Clear Form
          </button>
          <ul style={{ listStyle: 'none', padding: 0, marginTop: '20px' }}>
            {products.map((product) => (
              <li
                key={product.firestoreDocId}
                style={{
                  background: 'white',
                  marginBottom: '20px',
                  boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
                  borderRadius: '4px',
                  padding: '20px',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <img src={product.image} alt={product.title} style={{ width: '100px', height: '100px', marginRight: '20px' }} />
                <div>
                  <div style={{ fontSize: '20px', fontWeight: 'bold' }}>{product.title}</div>
                  <div style={{ fontSize: '16px', color: '#888' }}>Description: {product.description}</div>
                  <div style={{ fontSize: '16px' }}>Category: {product.category}</div>
                  <div>
                    Variants:
                    <ul>
                      {product.variants.map((variant, index) => (
                        <li key={index}>
                          {variant.name}: ${variant.price}
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div>
                    <button
                      onClick={() => deleteProduct(product.firestoreDocId)}
                      style={{
                        background: 'red',
                        color: 'white',
                        padding: '5px 10px',
                        border: 'none',
                        marginLeft: '10px',
                        cursor: 'pointer',
                      }}
                    >
                      Delete
                    </button>
                    <button
                      onClick={() => editProduct(product)}
                      style={{
                        background: 'blue',
                        color: 'white',
                        padding: '5px 10px',
                        border: 'none',
                        marginLeft: '10px',
                        cursor: 'pointer',
                      }}
                    >
                      Edit
                    </button>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}

      {/* List all products with edit and delete options */}
    </div>
  );
};

export default AdminPanel;
