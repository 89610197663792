import React from 'react';
import { Footer, Navbar } from '../components';

const AboutPage = () => {
  return (
    <>
      <Navbar />
      <div className="container my-3 py-3">
        <h1 className="text-center">  Welcome to <strong>Mag's Shop Shop</strong> - Your Source for Unique Custom Shirts and More!</h1>
        <hr />

        <div className="container text-center">
          <h2 className="py-4">Our Story</h2>
          <p>
            At <strong>Mag's Shop Shop</strong>, we believe that clothing is more than just fabric; it's an expression of your unique personality and style. We are a family-owned and operated business dedicated to bringing your creative visions to life through custom-designed shirts and other personalized items.
          </p>

          <h2 className="py-4">Who We Are</h2>
          <p>
            Founded by <strong>Magon</strong>, <strong>Mag's Shop Shop</strong> is a labor of love. With a passion for design and a commitment to quality, <strong>Magon</strong> embarked on this journey to create custom clothing and accessories that tell your story. Every item we craft is a reflection of your individuality, and we take pride in making products that you'll love wearing or gifting.
          </p>

          <h2 className="py-4">What Sets Us Apart</h2>
          <ul className="text-start">
            <li>
              <strong>Custom Creations:</strong> Whether you're looking for personalized shirts, unique accessories, or custom gifts, we specialize in turning your ideas into tangible, wearable art.
            </li>
            <li>
              <strong>Quality Matters:</strong> We believe that quality is non-negotiable. That's why we carefully select the finest materials and use cutting-edge printing techniques to ensure your items are not only visually stunning but also durable.
            </li>
            <li>
              <strong>Your Satisfaction:</strong> Your happiness is our priority. We work closely with you, ensuring that every detail of your design meets your expectations. Your satisfaction is our greatest reward.
            </li>
          </ul>

          <h2 className="py-4">Our Mission</h2>
          <p>
            Our mission is simple: to empower you to express yourself through clothing and accessories that are as unique as you are. We're dedicated to providing you with an exceptional shopping experience and products that spark joy and creativity.
          </p>

          <h2 className="py-4">Get in Touch</h2>
          <p>
            We're excited to collaborate with you on your next custom creation. Whether you have a design in mind or need inspiration, our team is here to assist you every step of the way.
          </p>
          <p>
            Ready to start your custom journey?  <a href="">Still in production email coming soon.</a> 
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AboutPage;
