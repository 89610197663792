import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AdminPanel from '../components/AdminPanel';

const isAdmin = true;  // Just a placeholder. Replace this with your actual logic.

const AdminGuard = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAdmin) {
      navigate('/login');
    }
  }, [navigate]);

  return isAdmin ? <AdminPanel /> : null;
}
export default AdminGuard;
